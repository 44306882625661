<template>
  <div class="pageBox">
    <div class="content-block">
      <h3 class="title text-center">请先完善账号资料</h3>
      <div class="dataStateBox">
        <!-- dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过 -->
        <div v-if="accountInfo.dataState==4" class="dataState">
          <p class="msg red">您的账号正在审核中，如需修改资料，请先撤回资料</p>
          <div class="blackBtn w100" @click="withdrawData()">撤回资料</div>
        </div>
        <div v-if="accountInfo.dataState==5" class="dataState">
          <p class="msg red">您的开发者账号资料审核失败，请重新编辑后再次提交，如有问题请通过微信公众号“移动智能终端生态联盟”反馈</p>
          <div class="blackBtn w100" @click="loocError()">查看原因</div>
        </div>
      </div>
      <el-form :model="form" label-width="160px" class="infoFormBox" :disabled="dataState">
        <el-row>
          <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
            <el-form-item label="开发者类型" prop="type">
              <el-radio-group
                aria-label="开发者类型"
                aria-autocomplete="none"
                placeholder="请勾选开发者类型"
                type="text"
                v-model="form.type"
              >
                <el-radio class="margin-r40" :label="1">企业</el-radio>
                <!-- <el-radio class="margin-r40" :label="2">个人</el-radio> -->
                <el-radio class="margin-r40" :label="3">政府/事业单位</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <Enterprise v-if="form.type==1" :dataState="dataState" :form="form"></Enterprise>
        <Personal v-if="form.type==2" :dataState="dataState" :form="form"></Personal>
        <Government v-if="form.type==3" :dataState="dataState" :form="form"></Government>
      </el-form>
    </div>
  </div>
</template>

<script>
import { isIdCardNo, checkPhone } from "@/utils/RegExp.js";
import { singleAccountSever } from "@/common/api-config";
import Enterprise from "./template/Enterprise"; //企业
import Government from "./template/Government"; //政府
import Personal from "./template/Personal"; //个人
export default {
  components: {
    Enterprise,
    Government,
    Personal,
  },
  created() {
    this.$store.dispatch("accountStore/updateUserData", {}).then((res) => {
      //去后台拿用户资料，更新本地状态
      if (res.code == 0) {
        if (res.data.type) {
          //是否有类型 ，有代表已经填写过资料
          this.form = Object.assign(this.form, utils.deepClone(res.data)); //拷贝出来给form
        }
      } else {
        this.$message.error("获取资料失败");
      }
    });
  },
  data() {
    return {
      form: {
        type: 1, //1 企业 2 个人 3 事业单位
        /*type=1：企业*/
        companyName: "", //企业名称
        licinseNumber: "", //营业执照号
        licinseNumberPhoto: "", //营业执照扫描件
        /*type=2：个人*/
        developId: "", //开发者身份证号
        developPositivePhoto: "", //开发者身份证正面
        developNegativePhoto: "", //开发者身份证反面
        developHoldPhoto: "", //开发者手持身份证
        /*type=3：政府/事业单位*/
        // companyName: "", // 与companyName共用key，利于搜索
        creditCode: "", //统一社会信用代码
        legalPersonCertificate: "", //法人证书
        corporationName: "", //法人姓名
        corporationCardName: "", //法人身份证
        legalPersonPositivePhoto: "", //法人身份证正面照片
        legalPersonNegativePhoto: "", //法人身份证反面照片
        legalPersonHoldPhotoType: "1", //是否有法人手持身份证：1 是 2 否
        legalPersonHoldPhoto: "", //法人手持身份证照片
        authorizePositivePhoto: "", //被授权人正面照片
        authorizeNegativePhoto: "", //被授权人反面照片
        authorizeHoldPhoto: "", //被授权人持身份证照片
        authorizeCertificate: "", //授权书
        /*共用*/
        contactName: "", //联系人姓名
        contactEmail: "", //联系人邮箱
        contactPhone: "", //联系人电话
        weixin: "", //微信
        qq: "", //QQ
      },
    };
  },
  computed: {
    // 用户详情
    accountInfo() {
      return this.$store.getters["accountStore/accountInfo"];
    },
    // 用户状态
    dataState() {
      //dataState:1-未完善 2-已完善 3-冻结 4-待审核 5-未通过
      let dataState = this.$store.getters["accountStore/accountInfo"].dataState;
      return dataState == 1 || dataState == 5 ? false : true;
    },
  },
  methods: {
    //查看审核不通过原因
    loocError() {
      this.$msgBox
        .alertMessageBox({
          title: "提示",
          content: this.form.reason,
        })
        .then(async (data) => {})
        .catch((val) => {});
    },
    //撤回资料
    withdrawData() {
      singleAccountSever.withdrawData().then((res) => {
        if (res.code == 0) {
          this.$message("撤回成功");
          this.updatedStore(); //更新本地状态store
        } else {
          this.$message.error("提交失败");
        }
      });
    },
    //更新本地状态store
    updatedStore() {
      this.$store
        .dispatch("accountStore/updateAccountInfo", {})
        .then((res) => {}); //去后台拿账户信息，更新本地状态
      this.$store.dispatch("accountStore/updateUserData", {}).then((res) => {
        //去后台拿用户资料，更新本地状态
        if (res.code == 0) {
          this.form = Object.assign(this.form, utils.deepClone(res.data));
        } else {
          this.$message.error("获取资料失败");
        }
      });
    },
  },
};
</script>
<style lang="scss">
//公共上传背景图片css
.infoForm {
  .businessBeforeImg {
    background: url(~@/assets/img/account/businessBefore.png) center no-repeat;
  }
  .idCardBackImg {
    background: url(~@/assets/img/account/idCardBack.png) center no-repeat;
    background-size: cover;
  }
  .IDcardFrontImg {
    background: url(~@/assets/img/account/IDcardFront.png) center no-repeat;
    background-size: cover;
  }
  .idCardHandImg {
    background: url(~@/assets/img/account/idCardHand.png) center no-repeat;
  }
}
.infoFormBox {
  .submitBotton {
    width: 400px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(40, 38, 44, 1);
    box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
<style scoped lang="scss">
.pageBox {
  .content-block {
    padding: 50px 0;
    width: 992px;
    .title {
      position: relative;
      margin: 50px 0;
      font-size: 24px;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -19px;
        width: 57px;
        height: 6px;
        background: #ded094;
        border-radius: 3px;
        transform: translateX(-50%);
      }
    }
    .dataStateBox {
      .dataState {
        margin: 20px 0;
        padding: 50px 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        .msg {
          font-size: 20px;
          width: 70%;
        }
        .btn {
          width: 20%;
          height: 50px;
          line-height: 50px;
          text-align: center;
          background: rgba(40, 38, 44, 1);
          box-shadow: 0px 7px 8px 0px rgba(0, 0, 0, 0.07);
          color: #ffffff;
          font-size: 16px;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .infoFormBox {
      padding: 50px 0;
      background: #fff;
    }
  }
}
</style>