<template>
  <el-form
    :model="form"
    :rules="rules"
    ref="form"
    label-width="160px"
    class="infoForm"
    :disabled="dataState"
  >
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="联系人姓名" prop="contactName">
          <el-input
            aria-label="联系人姓名"
            aria-autocomplete="none"
            clearable
            placeholder="请输入联系人姓名"
            type="text"
            v-model.trim="form.contactName"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="联系人邮箱" prop="contactEmail">
          <el-input
            aria-label="联系人邮箱"
            aria-autocomplete="none"
            clearable
            placeholder="请输入联系人邮箱"
            type="text"
            v-model.trim="form.contactEmail"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            aria-label="联系电话"
            aria-autocomplete="none"
            clearable
            placeholder="请输入联系电话"
            type="text"
            v-model.trim="form.contactPhone"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="微信" prop="weixin">
          <el-input
            aria-label="微信"
            aria-autocomplete="none"
            clearable
            placeholder="请输入微信"
            type="text"
            v-model.trim="form.weixin"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="QQ" prop="qq">
          <el-input
            aria-label="QQ"
            aria-autocomplete="none"
            clearable
            placeholder="请输入QQ"
            type="text"
            v-model.trim="form.qq"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item label="身份证号" prop="developId">
          <el-input
            aria-label="身份证号"
            aria-autocomplete="none"
            clearable
            placeholder="请输入身份证号"
            type="text"
            v-model.trim="form.developId"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <div class="flex">
          <el-form-item label="上传身份证" prop="developPositivePhoto">
            <el-upload
              class="uploaderFile"
              accept="image/png, image/PNG"
              :action="configBaseURL+'/api/upload'"
              :data="{type:'account'}"
              :show-file-list="false"
              :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'developPositivePhoto')}"
              :before-upload="beforeUpload"
            >
              <img v-if="form.developPositivePhoto" class="file" :src="form.developPositivePhoto" />
              <i v-else class="el-icon-plus icon idCardBackImg"></i>
            </el-upload>
          </el-form-item>
          <el-form-item prop="developNegativePhoto" label-width="10px">
            <el-upload
              class="uploaderFile"
              accept="image/png, image/PNG"
              :action="configBaseURL+'/api/upload'"
              :data="{type:'account'}"
              :show-file-list="false"
              :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'developNegativePhoto')}"
              :before-upload="beforeUpload"
            >
              <img v-if="form.developNegativePhoto" class="file" :src="form.developNegativePhoto" />
              <i v-else class="el-icon-plus icon IDcardFrontImg"></i>
            </el-upload>
          </el-form-item>
          <el-form-item prop="developHoldPhoto" label-width="160px">
            <el-upload
              class="uploaderFile"
              accept="image/png, image/PNG"
              :action="configBaseURL+'/api/upload'"
              :data="{type:'account'}"
              :show-file-list="false"
              :on-success="(res,file,filelist)=>{return handleUpload(res,file,filelist,'developHoldPhoto')}"
              :before-upload="beforeUpload"
            >
              <img v-if="form.developHoldPhoto" class="file" :src="form.developHoldPhoto" />
              <i v-else class="el-icon-plus icon idCardHandImg"></i>
            </el-upload>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item>
            <div class="themeColor">请上传身份证正反面及手持身份证反面图片，图片格式为png，大小不能超过2M。</div>
          </el-form-item>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="16" :offset="4" :xs="{span:22,offset:0}">
        <el-form-item>
          <div class="submitBotton" @click="submitForm('form')">提交</div>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { checkPhone, isIdCardNo, checkWeixin } from "@/utils/RegExp.js";
import { singleAccountSever } from "@/common/api-config";

export default {
  props: {
    dataState: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {},
  data() {
    return {
      rules: {
        /*type=2：个人*/
        developId: [
          { required: true, message: "请填写身份证", trigger: "blur" },
          {
            required: true,
            validator: isIdCardNo,
            trigger: ["blur", "change"],
          },
        ],
        developPositivePhoto: [
          { required: true, message: "请上传身份证正面照", trigger: "blur" },
        ],
        developNegativePhoto: [
          { required: true, message: "请上传身份证反面照", trigger: "blur" },
        ],
        developHoldPhoto: [
          { required: true, message: "请上传手持身份证照", trigger: "blur" },
        ],
        /*共用*/
        contactName: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contactEmail: [
          { required: true, message: "请输入联系人邮箱", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系人电话", trigger: "blur" },
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        weixin: [
          { required: true, message: "请输入联系人微信", trigger: "blur" },
          { required: true, validator: checkWeixin, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    //上传前校验
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //上传成功
    handleUpload(res, file, filelist, key) {
      if (res.code == 0) {
        this.form[key] = res.data.link;
        this.$refs.form.validateField(key);
      } else {
        this.$message.error("上传失败");
      }
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          singleAccountSever.improveData(this.form).then((res) => {
            if (res.code == 0) {
              this.$msgBox
                .alertMessageBox({
                  content:
                    "资料提交成功，请等待工作人员审核，预计将在3个工作日内完成",
                  delayed: 3,
                })
                .then(async (data) => {
                  this.$store
                    .dispatch("accountStore/updateAccountInfo", {})
                    .then((res) => {
                      //去后台拿账户信息，更新本地状态
                      this.$router.push("/account/perfect/status");
                    });
                })
                .catch((val) => {});
            } else {
              this.$message.error("提交失败");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>